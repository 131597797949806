import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <h2 className={'flex-justify-center'}>Nie znaleziono</h2>
    <p className={'flex-justify-center'}>Ta strona nie istnieje</p>
  </Layout>
)

export default NotFoundPage
